import React, { useState, useRef, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./create-category-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";
import { CirclePicker, SliderPicker } from "react-color";

export default function CreateCategoryForm(props) {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const categoryId = parseInt(queryParams.get("categoryId"), 10);

  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [color, setColor] = useState("#fff"); // Initialize color state
  const [flag, setFlag] = useState();

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  useEffect(() => {
    console.log(categoryId);
    if (categoryId) {
      getCategory();
    }
  }, []);

  const getCategory = async () => {
    const res = await baseAxios.get(`/categories/${categoryId}`);
    formData.current.name = res.data.category_name;
    handleColorChange(res.data.color);
    handleFlagChange(res.data.flag);
  };

  //flags array
  const flags = ["Chemical", "Non Chemical"];

  //flag change handler
  const handleFlagChange = (newFlag) => {
    setFlag(newFlag);
    formData.current.flag = newFlag;
  };

  const nameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Category Name",
  };

  const flagEditorOptions = {
    items: flags,
    value: flag, // Bind the flag value here
    searchEnabled: false,
    placeholder: "Choose a Flag",
    onValueChanged: ({ value }) => handleFlagChange(value),
  };

  //color change handler
  const handleColorChange = (color) => {
    setColor(color);
    formData.current.color = color;
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      // Assign organization to formData.current before destructuring
      formData.current.organization = userCredentials.Organization;
      const { flag, name, color, organization } = formData.current;

      // Check if the user's role includes 6, 3, or 2
      if (
        !userCredentials.Role.includes(6) &&
        !userCredentials.Role.includes(3) &&
        !userCredentials.Role.includes(2)
      ) {
        notify("User Not authorized, please check your role", "error", 5000);
        setLoading(false);
        return; // Exit the function if the user is not authorized
      }

      // Check if user picked color
      if (color === "#ffffff" || color === undefined) {
        notify("Please choose a color ", "error", 5000);
        setLoading(false);
        return;
      }

      try {
        if (categoryId) {
          await updateCategory();
        } else {
          await createCategory();
        }
      } catch (e) {
        if (e.status === 400) {
          notify(e.data.message, "Error", 5000);
        } else {
          notify("Operation failed: " + e.message, "error", 5000);
        }
      } finally {
        setLoading(false);
      }
    },
    [history]
  );

  const createCategory = async () => {
    const res = await baseAxios.post("categories/create", formData.current);
    if (res.status === 200) {
      notify("Category Created", "success", 3000);
      // Reload the page after a short delay
      setTimeout(() => {
        history.goBack();
      }, 1000); // Wait for the notification to show before reloading
    } else {
      notify("Category creation failed, please try again", "error", 5000);
    }
  };

  const updateCategory = async () => {
    const res = await baseAxios.post(
      `categories/update/${categoryId}`,
      formData.current
    );
    if (res.status === 200) {
      notify("Category Updated", "success", 3000);
      // Reload the page after a short delay
      setTimeout(() => {
        history.goBack();
      }, 1000); // Wait for the notification to show before reloading
    } else {
      notify("Category update failed, please try again", "error", 5000);
    }
  };

  return (
    <SingleCard title={categoryId ? "Update category" : "Add new category"}>
      <form className={"create-category-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={"name"}
            editorType={"dxTextBox"}
            editorOptions={nameEditorOptions}
          >
            <Label visible={false} />
            <RequiredRule message={"Please choose a category name"} />
          </Item>

          <Item
            dataField={"flag"}
            editorType={"dxSelectBox"}
            editorOptions={flagEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule message={"Please choose a flag"} />
          </Item>

          <Item itemType="group" colCount={1} caption="Choose a color">
            <Item>
              <Label text="Color" visible={false} />
              <CirclePicker
                color={color}
                onChangeComplete={(color) => handleColorChange(color.hex)}
              />
            </Item>
            <Item>
              <Label text="Color" visible={false} />
              <div style={{ position: "relative" }}>
                <SliderPicker
                  color={color}
                  onChange={(color) => handleColorChange(color.hex)}
                />
              </div>
            </Item>
          </Item>

          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : categoryId ? (
                  "Save category"
                ) : (
                  "Create a new category"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
    </SingleCard>
  );
}
