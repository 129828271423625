import React, { useState, useEffect } from "react";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import "./create-service-form.scss";
import { baseAxios } from "../../utils/config.js";

export const ServiceForm = ({ formData, loading, isEdit = false }) => {
  const [pricesLocked, setPricesLocked] = useState();

  const [categoryOptions, setCategoryOptions] = useState([]);
  //fetch categories by organization
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await baseAxios.get("/categories");
        const { categories } = response.data;
        const catOpt = categories.map((category) => {
          return {
            text: category.category_name,
            value: {
              id: category.category_id,
              name: category.category_name,
            },
          };
        });

        setCategoryOptions(catOpt);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCheckboxChanged = (e) => {
    if (e.value === true) {
      formData.current.price1 = 0;
      formData.current.price2 = 0;
      formData.current.price3 = 0;
      formData.current.price4 = 0;
      formData.current.price5 = 0;
      formData.current.taxesIncluded = false;
    }
    setPricesLocked(e.value);
  };

  //status array
  const statusOptions = ["Active", "Inactive"];

  const nameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Service name",
  };
  const shortcutNameEditorOptions = {
    maxLength: 10,
    stylingMode: "filled",
    placeholder: "Shortcut service name",
  };
  const descriptionEditorOptions = {
    stylingMode: "filled",
    placeholder: "Short description",
  };

  const statusEditorOptions = {
    items: statusOptions,
    searchEnabled: false,
    placeholder: "Choose a status",
  };
  const categoryEditorOptions = {
    items: categoryOptions,
    valueExpr: "value.id",
    displayExpr: "text",
    searchEnabled: true,
    placeholder: "Choose a category",
  };
  const priceEditorOptions = {
    placeholder: "Set a price",
    min: 0, // Ensuring the value can't be negative
    format: "$ #0.##",
    type: "dxNumberBox",
  };

  return (
    <Form formData={formData.current} disabled={loading}>
      <Item
        dataField={"name"}
        editorType={"dxTextBox"}
        editorOptions={nameEditorOptions}
      >
        <Label visible={false} />
        <RequiredRule message={"Please choose a service name"} />
      </Item>
      <Item
        dataField={"shortcutName"}
        editorType={"dxTextBox"}
        editorOptions={shortcutNameEditorOptions}
      >
        <Label visible={false} />
        <RequiredRule message={"Please choose a shortcut service name"} />
      </Item>
      <Item
        dataField={"description"}
        editorType={"dxTextArea"}
        editorOptions={descriptionEditorOptions}
      >
        <Label visible={false} />
        <RequiredRule
          message={"Please add a short description of the service"}
        />
      </Item>
      <Item
        dataField={"status"}
        editorType={"dxSelectBox"}
        editorOptions={statusEditorOptions}
        label={{ visible: false }}
      >
        <RequiredRule message={"Please choose a status"} />
      </Item>
      <Item
        dataField={"serviceCategory"}
        editorType={"dxSelectBox"}
        editorOptions={categoryEditorOptions}
        label={{ visible: false }}
      >
        <RequiredRule message={"Please choose a category"} />
      </Item>
      <Item itemType="group" colCount={2} caption="Set price levels">
        <Item
          colSpan={2}
          dataField={"freeService"}
          editorType={"dxCheckBox"}
          editorOptions={{
            onValueChanged: handleCheckboxChanged,
            text: "This service does not have a cost.",
          }}
          label={{ visible: false }}
        />

        <Item
          itemType="group"
          colCount={1}
          caption="Level 1"
          disabled={pricesLocked}
        >
          <Item
            caption={"Test"}
            dataField={"price1"}
            editorOptions={priceEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule />
          </Item>
        </Item>

        <Item
          itemType="group"
          colCount={1}
          caption="Level 2"
          disabled={pricesLocked}
        >
          <Item
            dataField={"price2"}
            editorOptions={priceEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule />
          </Item>
        </Item>

        <Item
          itemType="group"
          colCount={1}
          caption="Level 3"
          disabled={pricesLocked}
        >
          <Item
            dataField={"price3"}
            editorOptions={priceEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule />
          </Item>
        </Item>

        <Item
          itemType="group"
          colCount={1}
          caption="Level 4"
          disabled={pricesLocked}
        >
          <Item
            dataField={"price4"}
            editorOptions={priceEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule />
          </Item>
        </Item>

        <Item
          itemType="group"
          colCount={1}
          caption="Level 5"
          disabled={pricesLocked}
        >
          <Item
            dataField={"price5"}
            editorOptions={priceEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule />
          </Item>
        </Item>
      </Item>

      <Item
        disabled={pricesLocked}
        dataField={"taxesIncluded"}
        editorType={"dxCheckBox"}
        editorOptions={{
          text: "Taxes Included",
        }}
      >
        <Label visible={false} />
      </Item>

      <ButtonItem>
        <ButtonOptions width={"100%"} type={"default"} useSubmitBehavior={true}>
          <span className="dx-button-text">
            {loading ? (
              <LoadIndicator width={"24px"} height={"24px"} visible={true} />
            ) : isEdit ? (
              "Update Service"
            ) : (
              "Create a new Service"
            )}
          </span>
        </ButtonOptions>
      </ButtonItem>
    </Form>
  );
};
