import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { baseAxios } from "../../utils/config";
import { Box } from "@mui/material";

export default function ManageCategories() {
  const [dataLoaded, setDataLoaded] = useState(false);

  const history = useHistory();

  const dataSource = useMemo(
    () =>
      new CustomStore({
        load: function () {
          return baseAxios.get("/categories").then((result) => {
            let data = result.data;
            setDataLoaded(true);
            // Check if data is an array
            return data.categories;
          });
        },
        key: ["category_id"],
      }),
    []
  );

  const handleRowClick = (e) => {
    const categoryId = e.data.category_id;
    history.push({
      pathname: "/create-category",
      search: `?categoryId=${categoryId}`,
    });
  };

  const handleAddNewCategory = () => {
    history.push("/create-category");
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: "Add New Category",
        onClick: handleAddNewCategory,
      },
    });
  };

  const orderDateFormat = "M/d/yy";

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Manage Categories</h2>

      <DataGrid
        dataSource={dataSource}
        onToolbarPreparing={onToolbarPreparing}
        className={"dx-card wide-card"}
        showBorders={true}
        onRowClick={handleRowClick}
        focusedRowEnabled={dataLoaded}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <SearchPanel
          visible={true}
          highlightCaseSensitive={false}
          highlightSearchText={false}
          width="500px"
        />
        <HeaderFilter visible={true} />
        <Scrolling mode="standard" />
        <Column
          dataField="category_name"
          caption="Category Name"
          allowSearch={true}
        />
        <Column dataField="flag" caption="Flag" allowSearch={false}>
          <Lookup
            dataSource={[
              { id: "Chemical", name: "Chemical" },
              { id: "Non Chemical", name: "Non Chemical" },
            ]}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="color"
          caption="Color"
          allowSearch={false}
          cellRender={(params) => {
            return (
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  backgroundColor: params.value,
                }}
              />
            );
          }}
        />
      </DataGrid>
    </React.Fragment>
  );
}
