import React, { useState, useRef, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { changePassword } from "../../api/auth";
import { baseAxios } from "../../utils/config";

export default function ChangePasswordForm(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const location = useLocation();

  const email = location.state && location.state.email;
  if (!email) {
    history.push("/login");
    return null;
  }

  const onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const { oldPassword, password } = formData.current;
        setLoading(true);

        const result = await changePassword(email, oldPassword, password);
        setLoading(false);

        console.log(result);

        if (result.isOk) {
          notify(
            "Password changed successfully. Please log in with your new password.",
            "success",
            3000
          );
          history.push("/login");
        } else {
          notify(result.message, "error", 2000);
        }
      } catch {
        notify(
          "Error activating user, if having issues logging in please contant IT.",
          "error",
          2000
        );
      }
    },
    [history, email]
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"oldPassword"}
          editorType={"dxTextBox"}
          editorOptions={oldPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"confirmedPassword"}
          editorType={"dxTextBox"}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={"Passwords do not match"}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Continue"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const oldPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Old Password",
  mode: "password",
};
const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Password",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Confirm Password",
  mode: "password",
};
