import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./create-service-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config.js";
import { ServiceForm } from "./service-form.js";

export default function CreateServiceForm(props) {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ taxesIncluded: false });

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      // Assign organization to formData.current before destructuring
      formData.current.organization = userCredentials.Organization;

      // Check if the user's role includes 6, 3, or 2
      if (
        !userCredentials.Role.includes(6) &&
        !userCredentials.Role.includes(3) &&
        !userCredentials.Role.includes(2)
      ) {
        notify("User Not authorized, please check your role", "error", 5000);
        setLoading(false);
        return; // Exit the function if the user is not authorized
      }

      if (
        !formData.current.freeService &&
        formData.current.price1 === 0 &&
        formData.current.price2 === 0 &&
        formData.current.price3 === 0 &&
        formData.current.price4 === 0 &&
        formData.current.price5 === 0
      ) {
        notify(
          "At least one price level must be set if the service has a cost.",
          "Error",
          3000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await baseAxios.post(
          "/services/create",
          formData.current
        );

        if (response.status === 200) {
          notify("Service Created", "success", 3000);
          // Reload the page after a short delay
          setTimeout(() => {
            history.go(0);
          }, 3000);
        } // Wait for the notification to show before reloading
      } catch (e) {
        if (e.status === 400) {
          notify(e.data.message, "error", 5000);
        } else {
          notify("Service creation failed, please try again", "error", 5000);
        }
      } finally {
        setLoading(false);
      }
    },
    [history]
  );

  return (
    <SingleCard title="Add new Service">
      <form className={"create-service-form"} onSubmit={onSubmit}>
        <ServiceForm formData={formData} loading={loading} />
      </form>
    </SingleCard>
  );
}
