import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

import UserPool from "../UserPool";
import { useContext, useEffect } from "react";
import {
  APP_ID,
  APP_SECRET,
  BASE_URL,
  baseAxios,
  Context,
} from "../utils/config";
import axios from "axios";

var user, userAttributes;

export const getAccessToken = async () => {
  try {
    const userData = await getCurrentSession();

    return userData.accessToken.jwtToken;
  } catch {
    return "";
  }
};
export async function signIn(email, password) {
  var userData = {
    Username: email,
    Pool: UserPool,
  };

  user = new CognitoUser(userData);

  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  //Send request
  return new Promise((resolve) => {
    user.authenticateUser(authDetails, {
      onSuccess: async (data) => {
        try {
          var userAuthorizationData;
          userAuthorizationData = await getUserAuthorization(
            data.idToken.payload["sub"]
          );

          resolve({
            isOk: true,
            data: {
              email: data.idToken.payload.email,
              avatarUrl: null,
              accessToken: data.accessToken,
              idToken: data.idToken,
              role: data.idToken.payload["custom:Role"],

              location_id: userAuthorizationData.user_location_id,
              location_name: userAuthorizationData.location_name,
              userName: userAuthorizationData.user_name,
              userMeevoID: userAuthorizationData.user_meevo_id,
              userID: userAuthorizationData.user_id,
              barcode: userAuthorizationData.user_barcode,
              userRoles: userAuthorizationData.user_roles,
              org: userAuthorizationData.org_id.toString(),
            },
          });
        } catch (e) {
          console.log(e);
          resolve({
            isOk: false,
            message: "Error signing in, please try again later.",
          });
        }
      },
      newPasswordRequired: function (_userAttributes) {
        userAttributes = _userAttributes;
        resolve({
          isOk: false,
          updatePassword: true,
          message:
            "Password is required to be reset. Please set a new password.",
        });
      },
      onFailure: (err) => {
        console.log(err);
        if (err.code === "PasswordResetRequiredException") {
          resolve({
            resetPassword: true,
            isOk: false,
            message:
              "Password is required to be reset. Please check your email for the code.",
            error: err,
          });
        } else if (err == "UserNotConfirmedException: User is not confirmed.") {
          resolve({
            unconfirmed: true,
            isOk: false,
            message:
              "User not yet confirmed. Please check your email or contact your admin.",
            error: err,
          });
        } else {
          resolve({
            isOk: false,
            message:
              "Either your username or password is incorrect. Please check and try again.",
            error: err,
          });
        }
      },
    });
  });
}

export function signOut() {
  user.signOut();
}

export function getCurrentSession() {
  return new Promise((resolve, reject) => {
    user.getSession(async (err, session) => {
      if (err) {
        reject(err);
      } else if (!session.isValid()) {
        reject("Invalid session.");
      } else {
        resolve(session);
      }
    });
  });
}

export async function getUser() {
  try {
    user = UserPool.getCurrentUser();
    if (user != null) {
      try {
        const session = await getCurrentSession();

        var userAuthorizationData;
        userAuthorizationData = await getUserAuthorization(
          session.idToken.payload["sub"]
        );

        return {
          isOk: true,
          data: {
            email: session.idToken.payload.email,
            avatarUrl: null,
            accessToken: session.accessToken,
            idToken: session.idToken,
            //barcode: data.getIdToken().payload["custom:Barcode"],
            role: session.idToken.payload["custom:Role"],
            //org: data.getIdToken().payload[`custom:Organization`],

            location_id: userAuthorizationData.user_location_id,
            location_name: userAuthorizationData.location_name,
            userName: userAuthorizationData.user_name,
            userMeevoID: userAuthorizationData.user_meevo_id,
            userID: userAuthorizationData.user_id,
            barcode: userAuthorizationData.user_barcode,
            userRoles: userAuthorizationData.user_roles,
            org: userAuthorizationData.org_id.toString(),
            // refreshToken: data.getRefreshToken().getJwtToken(),
          },
        };
      } catch (e) {
        console.log("No user");
        return { isOk: false, message: "No user" };
      }
    } else {
      console.log("User not found.");
      return {
        isOk: false,
      };
    }
  } catch (e) {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(
  firstName,
  lastName,
  email,
  phone,
  roles,
  location,
  barcode
) {
  try {
    await baseAxios.post(`/users`, {
      firstName,
      lastName,
      email,
      phone,
      barcode,
      location,
      roles,
    });

    return {
      isOk: true,
      message: "Email sent to new user for verification.",
    };
  } catch {
    return {
      isOk: false,
      message: "Error creating new user",
    };
  }
}

export async function editAccountObject(
  id,
  {
    user_first_name,
    user_last_name,
    user_email,
    user_phone,
    roles,
    location_id,
    user_status,
    user_barcode,
  }
) {
  return await editAccount(
    id,
    user_first_name,
    user_last_name,
    user_email,
    user_phone,
    roles,
    location_id,
    user_barcode,
    user_status
  );
}

export async function editAccount(
  id,
  firstName,
  lastName,
  email,
  phone,
  roles,
  location,
  barcode,
  status
) {
  try {
    await baseAxios.post(`/users/${id}`, {
      firstName,
      lastName,
      email,
      phone,
      barcode,
      status,
      location,
      roles,
    });

    return {
      isOk: true,
      message: "User updated",
    };
  } catch {
    return {
      isOk: false,
      message: "Error updating user",
    };
  }
}

export async function changePassword(email, oldPassword, newPassword) {
  delete userAttributes.email;
  try {
    await baseAxios.post("users/changeInitialPassword", {
      email,
      oldPassword,
      newPassword,
    });
    return {
      isOk: true,
    };
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    };
  }
}

export async function resetPassword(email) {
  return new Promise((resolve) => {
    // Send request
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.forgotPassword({
      onSuccess: function (data) {
        console.log(data);
        resolve({
          isOk: true,
        });
      },
      onFailure: function (err) {
        console.log(err);
        resolve({
          isOk: false,
          message: err,
        });
      },
    });
  });
}

export async function confirmPassword(email, verificationCode, newPassword) {
  return new Promise((resolve) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: function (data) {
        console.log(data);
        resolve({
          isOk: true,
        });
      },
      onFailure: function (err) {
        console.log(err);
        resolve({
          isOk: false,
          message: err,
        });
      },
    });
  });
}

//This is for meevo
export async function getToken() {
  return new Promise((resolve) => {
    axios
      .post(`${BASE_URL}/oauth2/token`, {
        client_id: APP_ID,
        client_secret: APP_SECRET,
      })
      .then((res) => {
        resolve({
          isOk: true,
          data: res.data,
        });
      });
  });
}

//This is for meevo
export function useToken() {
  const [token, setToken] = useContext(Context);

  useEffect(async () => {
    if (!token) {
      try {
        const res = await getToken();
        if (res.isOk) {
          const newToken = `${res.data?.token_type} ${res.data?.access_token}`;
          setToken(newToken);
        }
      } catch (err) {
        console.log("Error fetching token", err);
      }
    }
  });
}

function getUserAuthorization(username) {
  let userData = {
    username: username,
  };
  JSON.stringify(userData);

  return new Promise((resolve, reject) => {
    baseAxios
      .post("/users/userAuthorization", userData)
      .then((result) => {
        resolve(result.data.Items[0]);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
