import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./edit-service-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";
import { ServiceForm } from "../create-service-form/service-form";

export default function EditServiceForm(props) {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState([]);
  const formData = useRef({ taxesIncluded: false });
  const [formRenderKey, setFormRenderKey] = useState(0);

  const getServiceById = "gateway/2m2zym0pu2";

  const editServiceById = "gateway/cz2m0mg7u4";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceId = queryParams.get("serviceId");

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  //used for the tax checkbox
  const toBoolean = (value) => {
    return value === "1" || value === true;
  };

  useEffect(() => {
    document.body.style.cursor = loading ? "progress" : "default";

    return () => {
      document.body.style.cursor = "default";
    };
  }, [loading]);

  //fetch service by id and set formData
  useEffect(() => {
    setLoading(true);
    const fetchServiceById = async () => {
      const payload = {
        serviceId: serviceId,
        role: userCredentials.Role,
        organization: userCredentials.Organization,
      };
      try {
        const response = await baseAxios.post(getServiceById, payload);

        const serviceData = response.data[0];

        if (serviceData) {
          serviceData.taxes_included = toBoolean(serviceData.taxes_included);
          setService(serviceData);
          formData.current = {
            ...formData.current,
            name: serviceData.service_name,
            shortcutName: serviceData.service_shortcut,
            description: serviceData.description,
            status: serviceData.service_status,
            serviceCategory: Number(serviceData.category_id),
            freeService: serviceData.service_price_level_1 === 0,
            price1: serviceData.service_price_level_1,
            price2: serviceData.service_price_level_2,
            price3: serviceData.service_price_level_3,
            price4: serviceData.service_price_level_4,
            price5: serviceData.service_price_level_5,
            taxesIncluded: serviceData.taxes_included,
          };
          setFormRenderKey((prevKey) => prevKey + 1);
        }
      } catch (error) {
        console.error("Failed to fetch service details", error);
      }
    };
    if (serviceId) {
      fetchServiceById();
    }
    setLoading(false);
  }, [serviceId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      // Todo check this when handling roles unification
      !userCredentials.Role.includes(6) &&
      !userCredentials.Role.includes(3) &&
      !userCredentials.Role.includes(2)
    ) {
      notify("User Not authorized, please check your role", "error", 5000);
      setLoading(false);
      return;
    }

    if (
      !formData.current.freeService &&
      formData.current.price1 === 0 &&
      formData.current.price2 === 0 &&
      formData.current.price3 === 0 &&
      formData.current.price4 === 0 &&
      formData.current.price5 === 0
    ) {
      notify(
        "At least one price level must be set if the service has a cost.",
        "Error",
        3000
      );
      setLoading(false);
      return;
    }

    try {
      const response = await baseAxios.post(
        `/services/editService/${service.service_id}`,
        {
          ...formData.current,
          organization_id: service.organization_id,
        }
      );

      if (response.status === 200) {
        notify("Service Updated", "success", 3000);

        setTimeout(() => {
          history.push("/search-services");
        }, 3000);
      }
      setLoading(false);
    } catch (e) {
      notify("Service creation failed, please try again", "error", 5000);
      setLoading(false);
    }
  };

  return (
    <div style={{ cursor: loading ? "progress" : "default" }}>
      <SingleCard title="Edit Service">
        <form className={"edit-service-form"} onSubmit={onSubmit}>
          <ServiceForm loading={loading} formData={formData} isEdit={true} />
        </form>
      </SingleCard>
    </div>
  );
}
